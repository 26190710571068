import Appsignal from '@appsignal/javascript';
import { plugin as pathDecoratorPlugin } from '@appsignal/plugin-path-decorator';
import { plugin as networkBreadcrumbsPlugin  } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as windowEventPlugin } from '@appsignal/plugin-window-events';
let instance = null;

export default function AppsignalInstance(key) {
  if (!instance && key) {
    instance = new Appsignal({ key });
    instance.use(pathDecoratorPlugin());  // Get the path from the browser
    instance.use(windowEventPlugin({ onunhandledrejection: false }));    // Capture all uncaught errors (NOT rejections)
    instance.use(networkBreadcrumbsPlugin({ ignoreUrls: [/\/mini-profiler-resources*/] })); // Capture XHR as breadcrumbs
  }
  return instance;
};